import { AccountStorage } from "@services/account";
var accountStorage = new AccountStorage();
export default {
  components: {},
  data: function data() {
    return {
      title: "新增发票",
      compile: "编辑发票",
      isInvoice: false,
      info: {
        title: "",
        duty: "",
        site: "",
        bank: "",
        phone: "",
        account: ""
      },
      data: [],
      detailId: null,
      checkboxCustom: 0,
      companyId: "",
      isTitle: false,
      toTitle: false,
      check: null
    };
  },
  created: function created() {
    var loginCompanyId = accountStorage.getCacheUserInfo();
    this.companyId = loginCompanyId.loginCompanyId;
    this.getInvoiceList();
  },
  methods: {
    blurTitle: function blurTitle() {
      if (this.info.title === "") {
        this.isTitle = true;
      } else {
        this.isTitle = false;
      }
    },
    onSearch: function onSearch() {},
    // 列表
    getInvoiceList: function getInvoiceList() {
      var _this = this;
      var url = "/pjyy-deepexi-user-center/api/v1/invoice/invoiceList?companyId=".concat(this.companyId);
      this.$getHttpClient().get(url).then(function (res) {
        _this.data = res.data.data;
      });
    },
    // 确定 新增 || 编辑
    handleSave: function handleSave() {
      var _this2 = this;
      if (this.info.title === "") {
        this.isTitle = true;
        return false;
      }
      this.isInvoice = false;
      var data = {
        bank: this.info.bank,
        // 开户银行
        bankAccount: this.info.account,
        // 银行账号
        companyAddress: this.info.site,
        // 单位地址
        companyId: this.companyId,
        // 企业id
        defaultStatus: this.checkboxCustom,
        // 默认
        id: this.detailId,
        // id
        phoneNumber: this.info.phone,
        // 电话号码
        taxNumber: this.info.duty,
        // 税号
        title: this.info.title // 发票抬头
      };
      var url = "/pjyy-deepexi-user-center/api/v1/invoice/save";
      this.$getHttpClient().post(url, data).then(function (res) {
        if (res.data.code === "200") {
          _this2.info.site = "";
          _this2.info.bank = null;
          _this2.info.account = "";
          _this2.info.phone = "";
          _this2.info.duty = "";
          _this2.info.title = "";
          _this2.getInvoiceList();
        }
      });
      this.toTitle = false;
    },
    // 删除
    deleteById: function deleteById(id) {
      var _this3 = this;
      var url = "/pjyy-deepexi-user-center/api/v1/invoice/deleteById?id=".concat(id);
      this.$getHttpClient().get(url).then(function (res) {
        if (res.data.code === "200") {
          _this3.getInvoiceList();
        }
      });
    },
    // 设为默认
    handleDefault: function handleDefault(id) {
      var that = this;
      this.$buefy.dialog.confirm({
        message: "确定设为默认吗？",
        confirmText: "确定",
        cancelText: "取消",
        type: "is-info",
        onConfirm: function onConfirm() {
          var _this4 = this;
          var url = "/pjyy-deepexi-user-center/api/v1/invoice/setDefault?id=".concat(id);
          this.$getHttpClient().get(url).then(function (res) {
            if (res.data.code === "200") {
              that.getInvoiceList();
              _this4.$buefy.toast.open("设置成功");
            }
          });
        }
      });
    },
    // // 关闭默认
    // closeDefault(id) {
    //   let url = `/pjyy-deepexi-user-center/api/v1/invoice/setDefault?id=${id}`
    //   this.$getHttpClient().get(url)
    //       .then(res => {
    //         if (res.data.data) {
    //           this.getInvoiceList()
    //         }
    //       })
    // },
    // 编辑
    handleDetail: function handleDetail(row) {
      this.check = row.defaultStatus;
      this.isInvoice = true;
      if (this.isInvoice) {
        this.toTitle = true;
      }
      this.detailId = row.id;
      this.info.bank = row.bank;
      this.info.account = row.bankAccount;
      this.info.site = row.companyAddress;
      this.info.phone = row.phoneNumber;
      this.info.duty = row.taxNumber;
      this.info.title = row.title;
    },
    // 点击新增
    handleInvoice: function handleInvoice() {
      this.isInvoice = true;
    },
    handleCancel: function handleCancel() {
      this.isInvoice = false;
      this.toTitle = false;
      this.info = {
        title: "",
        duty: "",
        site: "",
        bank: "",
        phone: "",
        account: ""
      };
    }
  },
  updated: function updated() {},
  mounted: function mounted() {}
};